import { useGeoIPLookup } from "@karbonhq/marketing-shared-utils/hooks/useGeoIPLookup"
import type {
  KarbonUserData,
  KarbonUserInfo,
} from "@karbonhq/marketing-shared-utils/hooks/useKarbonUserData"
import { useKarbonUserData } from "@karbonhq/marketing-shared-utils/hooks/useKarbonUserData"
import React, { useEffect, useMemo, useState } from "react"

interface IVisitorLocaleContext {
  countryCode: countryCodes
  setCountryCode: (code: countryCodes) => void
  karbonData: KarbonUserInfo
  checkIsLoggedIn: (
    karbonData?: KarbonUserData | Partial<KarbonUserData>
  ) => karbonData is KarbonUserData
}
export const VisitorLocaleContext = React.createContext<IVisitorLocaleContext>({
  countryCode: "US",
  setCountryCode: () => {
    /* this is a placeholder */
  },

  karbonData: {
    data: { dataCenterUrl: "https://us3.karbonhq.com" },
    errors: {},
    isLoggedIn: false,
  },
  checkIsLoggedIn: function (_data): _data is KarbonUserData {
    return false
  },
})
export const VisitorLocaleProvider = props => {
  const [countryCode, setCountryCode] = useState<countryCodes>("US")
  const geoIP = useGeoIPLookup()
  const karbonData = useKarbonUserData()

  useEffect(
    function handleGeoIP() {
      // eslint-disable-next-line camelcase
      if (geoIP?.data?.country_code) {
        // eslint-disable-next-line no-console
        console.info("🌍: ", geoIP.data.country_code)
        setCountryCode(geoIP.data.country_code as countryCodes)
      }
    },
    [geoIP.data]
  )

  const providerData: IVisitorLocaleContext = useMemo(
    () => ({
      countryCode,
      setCountryCode,
      karbonData,
      checkIsLoggedIn: (data = karbonData.data): data is KarbonUserData =>
        !!data?.emailAddress && !!data?.userProfilePermaKey,
    }),
    [countryCode, karbonData]
  )

  return (
    <VisitorLocaleContext.Provider value={providerData}>
      {props.children}
    </VisitorLocaleContext.Provider>
  )
}
