/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { VisitorLocaleProvider } from "@src/components/providers/VisitorLocaleProvider"
import { track } from "@src/utils/analytics"
import React from "react"
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// add visitorlocale context provider
export const wrapRootElement = ({ element }) => (
  <VisitorLocaleProvider>{element}</VisitorLocaleProvider>
)

export const onRouteUpdate = ({ location, prevLocation }) => {
  let sent = [] // collect all the ones we already sent
  let pageLoadedTime = new Date()
  let firstScrollTime
  const trackScroll = () => {
    const now = new Date()
    if (!firstScrollTime) firstScrollTime = now // Datestamp when we start scrolling.
    const waypoints = [5, 25, 50, 75, 90, 100]
    const h = document.documentElement
    const b = document.body
    const st = "scrollTop"
    const sh = "scrollHeight"

    const percent = parseInt(
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
    )

    if (waypoints.includes(percent) && !sent.includes(percent)) {
      sent.push(percent)

      track(`WE-ScrollPage ${percent}%`, {
        nonInteraction: 1,
        timeSinceFirstScroll: Math.round((now - firstScrollTime) / 1000),
        timeSincePageload: Math.round((now - pageLoadedTime) / 1000),
      })
    }
  }
  document.addEventListener("scroll", trackScroll)
}
