// A light wrapper aroung GTM to make it easier to use and generic

const isClient = () => typeof window !== "undefined"
const dataLayer = isClient() ? (window.dataLayer = window.dataLayer || []) : []

export const track = (event: string, data: Record<string, unknown> = {}) => {
  if (isClient()) {
    dataLayer.push({
      event,
      ...data,
    })
  }
}

export const trackWithCallback = (
  event: string,
  data: Record<string, unknown> = {},
  callback: (...args: unknown[]) => unknown,
  delay = 300
) => {
  track(event, data)
  setTimeout(callback, delay)
}

export const trackLink = (
  link: HTMLAnchorElement,
  event: string,
  data: Record<string, unknown> = {}
) => {
  const listener = e => {
    e.preventDefault()
    trackWithCallback(event, data, () => {
      link.href = window.location.href = link.href
    })
  }

  link.addEventListener("click", listener)
  return () => link.removeEventListener("click", listener)
}
